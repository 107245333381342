import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Collapse from "components/Collapse";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "collapse"
    }}>{`Collapse`}</h1>

    <Playground __position={0} __code={'() => {\n  const [isOpen, setIsOpen] = React.useState(false)\n  const toggle = () => setIsOpen(!isOpen)\n  return (\n    <>\n      <a\n        className=\"btn btn-primary\"\n        onClick={toggle}\n        style={{ marginBottom: \'1rem\' }}\n      >\n        Toggle\n      </a>\n      <Collapse isOpen={isOpen}>Lorem ipsum dolor sit amet.</Collapse>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Collapse,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = React.useState(false);

        const toggle = () => setIsOpen(!isOpen);

        return <>
        <a className="btn btn-primary" onClick={toggle} style={{
            marginBottom: '1rem'
          }}>Toggle</a>
        <Collapse isOpen={isOpen} mdxType="Collapse">
          Lorem ipsum dolor sit amet.
        </Collapse>
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/collapse/" target="_blank">Reactstrap Collapse</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      